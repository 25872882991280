import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { Observable, of } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class CartService {
    counterProductsInCart = new Subject();
    ProductsLocalStorage = new Subject();
    orderPlaced = new Subject();
    sidenavOpen = new Subject();

    changeOrder(data: any) {
        this.ProductsLocalStorage.next(data);
    }

    counterShoppingCart(data: any) {
        this.counterProductsInCart.next(data);
    }

    orderPlacedReset(data: any) {
        this.orderPlaced.next(data);
    }

    changeSidenavStatus(data: boolean) {
        this.sidenavOpen.next(data);
    }

    constructor() {}
}
