import { Component, OnInit, Input } from "@angular/core";
import { ApiResponseModel } from "../shared/entities/api-response.model";
import { LocationService } from "../services/location.service";
import { LocationResponseDataModel } from "../types/location.model";
import { CategoryModel } from "../types/category.model";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { finalize, mergeMap, switchMap, map, tap } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
    selector: "app-app-location-landing-page",
    templateUrl: "./app-location-landing-page.component.html",
    styleUrls: ["./app-location-landing-page.component.css"],
})
export class AppLocationLandingPageComponent implements OnInit {
    public menu: boolean = true;
    public loading: boolean = true;
    public locationUid: string;

    public jsonData: LocationResponseDataModel;

    public locationTest: any;

    constructor(
        public locationService: LocationService,
        private route: ActivatedRoute,
        private router: Router,
    ) {}

    public MenuChange(event) {
        this.menu = event;
    }

    ngOnInit(): void {
        var order = JSON.parse(localStorage.getItem("order"));
        this.locationUid = this.route.snapshot.queryParams["uid"];
        const uid = "8eecfb38-5705-4620-90b5-adac87304b21";

        if (!this.locationUid) {
            this.router.navigate(["404"]);
            return;
        }

        this.locationService
            .getLocation(this.locationUid)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe({
                next: (response: ApiResponseModel) => {
                    // const module = response.payload.company.modules.find(
                    //     (element) => element.module === "digital_menu",
                    // )?.active;
                    // if (!module) {
                    //     this.router.navigate(["404"]);
                    //     return;
                    // }
                    this.locationTest = response.payload;
                },
                error: (err) => {
                    this.router.navigate(["404"]);
                },
            });
    }
}
