import { Component, OnInit, Input } from "@angular/core";
import { ImagesModel } from "src/app/types/images.model";

@Component({
    selector: "app-app-page-header",
    templateUrl: "./app-page-header.component.html",
    styleUrls: ["./app-page-header.component.css"],
})
export class AppPageHeaderComponent implements OnInit {
    @Input() companyLogo = null;
    @Input() locationBackground = null;

    constructor() {}

    ngOnInit(): void {}
}
