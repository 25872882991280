<div class="container">
    <input
        type="text"
        #searchInput
        class="search-input"
        placeholder="Cauta un produs..."
    />

    <button class="search-button" (click)="searchItem(searchInput.value)">
        Cauta
    </button>
</div>
