import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-app-search-area",
    templateUrl: "./app-search-area.component.html",
    styleUrls: ["./app-search-area.component.css"],
})
export class AppSearchAreaComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
