<app-app-product-search (search)="onSearchProduct($event)">
</app-app-product-search>
<div class="col-md-8 mx-auto categories-carousel">
    <app-app-categories-carousel
        [categoriesCarousel]="categories"
        (categoryChange)="countChange($event)"
    ></app-app-categories-carousel>
</div>

<div class="shop-page single padding-tb bg-fa">
    <div class="container">
        <div class="section-wrapper">
            <div class="shop-title d-flex flex-wrap justify-content-between">
                <p>
                    {{ product_on_category_counter }} din
                    {{ total_counter }} produse
                </p>
                <div class="product-view-mode">
                    <a
                        data-target="grid"
                        (click)="changeModeTo('grid')"
                        [ngClass]="
                            showMode === 'grid' ? 'active' : 'black-class'
                        "
                        ><i class="icofont-ghost"></i
                    ></a>
                    <a
                        data-target="list"
                        (click)="changeModeTo('list')"
                        [ngClass]="
                            showMode === 'list' ? 'active' : 'black-class'
                        "
                        ><i class="icofont-listing-box"></i
                    ></a>
                </div>
            </div>
            <div class="shop-product-wrap {{ showMode }} row">
                <!--Loading screen-->
                <div *ngIf="categoryLoading">
                    <ngx-loading [show]="categoryLoading"></ngx-loading>
                </div>

                <div *ngIf="!categoryLoading">
                    <div *ngFor="let produs of products_data">
                        <div class="col-xl-3 col-md-6 col-12">
                            <!--FIRST SHOW MODE-->
                            <div class="product-item">
                                <a
                                    data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasBottom"
                                    data-bs-backdrop="true"
                                    aria-controls="offcanva"
                                    (click)="showProductDetails(produs)"
                                    style="width: 100%"
                                >
                                    <div class="container" style="width: 100%">
                                        <div>
                                            <!--                                            <div class="top-right">{{produs.price | number: '1.2-2'}} RON</div>-->
                                            <div class="top-right">
                                                <span>{{
                                                    produs.price
                                                        | number: "1.2-2"
                                                }}</span>
                                                <span>RON</span>
                                            </div>
                                        </div>
                                        <div class="container">
                                            <img
                                                src="{{
                                                    produs.defaultImageUrl
                                                }}"
                                                style="
                                                    max-width: 100%;
                                                    min-height: 157px;
                                                    max-height: 157px;
                                                    width: auto;
                                                "
                                                class="product-image-list"
                                            />
                                            <!--<section class="page-header style-2 img-fluid" style="background-image:url({{produs.defaultImage}});background-size: 100% 98%;">
                                                                  </section>-->
                                            <!--<img src ="{{produs.defaultImage}}" style="object-fit:contain;min-width: 100%;height: auto;">-->
                                        </div>
                                        <div class="product-content">
                                            <div class="product-title">
                                                <span class="cat-name">{{
                                                    produs.name
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </a>

                                <div style="text-align: center">
                                    <button
                                        class="add-button"
                                        (click)="
                                            addProductToLocalStorage(produs)
                                        "
                                        style="
                                            background-color: #009675;
                                            border: none;
                                            border-radius: 12px;
                                            padding: 8px 25px;
                                            color: white;
                                        "
                                    >
                                        <i
                                            class="icofont-heart"
                                            style="
                                                color: white;
                                                font-size: 20px;
                                            "
                                        ></i>
                                        Adauga
                                    </button>
                                </div>
                            </div>
                            <!--SECOND SHOW MODE-->
                            <div class="product-list-item">
                                <div
                                    class="product-content"
                                    style="padding-left: 0px"
                                >
                                    <a
                                        data-bs-toggle="offcanvas"
                                        data-bs-target="#offcanvasBottom"
                                        aria-controls="offcanva"
                                        (click)="showProductDetails(produs)"
                                    >
                                        <div class="row">
                                            <div class="col-5">
                                                <img
                                                    src="{{
                                                        produs.defaultImageUrl
                                                    }}"
                                                    style="
                                                        object-fit: contain;
                                                        min-width: 100%;
                                                        height: auto;
                                                    "
                                                />
                                            </div>
                                            <div class="col-7">
                                                <span
                                                    class="name"
                                                    style="
                                                        font-size: 18px;
                                                        font-weight: 600;
                                                    "
                                                    >{{ produs.name }}</span
                                                >
                                                <br />
                                                <span
                                                    class="price"
                                                    style="
                                                        color: #009675;
                                                        font-size: 15px;
                                                    "
                                                    >{{
                                                        produs.price
                                                            | number: "1.2-2"
                                                    }}
                                                    RON</span
                                                >
                                                <div
                                                    *ngIf="
                                                        produs.short_description
                                                    "
                                                >
                                                    <span class="desciption">{{
                                                        produs.short_description
                                                    }}</span>
                                                </div>
                                                <div
                                                    *ngIf="
                                                        !produs.short_description
                                                    "
                                                    style="font-size: 14px"
                                                >
                                                    Nu exista descriere scurta
                                                    pentru acest produs
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    <div style="padding-top: 3px">
                                        <button
                                            class="add-button"
                                            (click)="
                                                addProductToLocalStorage(produs)
                                            "
                                            style="
                                                background-color: #009675;
                                                border: none;
                                                border-radius: 12px;
                                                padding: 8px 25px;
                                                color: white;
                                            "
                                        >
                                            <i
                                                class="icofont-heart"
                                                style="
                                                    color: white;
                                                    font-size: 20px;
                                                "
                                            ></i>
                                            Adauga
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Offcanvas menu content-->
                        <div
                            class="offcanvas offcanvas-bottom"
                            tabindex="-1"
                            id="offcanvasBottom"
                            aria-labelledby="offcanvasBottomLabel"
                            style="height: 90%"
                        >
                            <!--<app-app-product-description [currentProductMenu]="currentProductMenu" [setUpdateButton]="setUpdateButton" [disabledButton]="disabledButton"></app-app-product-description>-->
                            <app-app-product-description
                                [currentProductMenu]="currentProductMenu"
                                [setUpdateButton]="setUpdateButton"
                            ></app-app-product-description>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-app-product-paginator
    [page]="page"
    (pageChange)="onPageUpdate($event)"
    [totalPages]="totalPages"
>
</app-app-product-paginator>
