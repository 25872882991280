import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatStepperModule } from "@angular/material/stepper";
import {
    FormGroupDirective,
    FormsModule,
    ReactiveFormsModule,
} from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { TableCommunicationService } from "./shared/services/table-communication.service";
import { DatePipe, DecimalPipe } from "@angular/common";
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogModule,
} from "@angular/material/dialog";
import { MatSelectModule } from "@angular/material/select";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatOptionModule } from "@angular/material/core";
import { MatInputModule } from "@angular/material/input";
import { MatTabsModule } from "@angular/material/tabs";
import { MatRadioModule } from "@angular/material/radio";
import { NgSelectModule } from "@ng-select/ng-select";
import { ToastrModule, ToastrService } from "ngx-toastr";
import { NgxLoadingModule, ngxLoadingAnimationTypes } from "ngx-loading";
import { TwoDigitDecimaNumberDirective } from "./services/TwoDigit";
import { AppProductsComponent } from "./app-products/app-products.component";
import { AppOrderComponent } from "./app-order/app-order.component";
import { AppLocationLandingPageComponent } from "./app-location-landing-page/app-location-landing-page.component";
import { AppPreloaderComponent } from "./layout/app-preloader/app-preloader.component";
import { AppMobileMenuComponent } from "./layout/app-mobile-menu/app-mobile-menu.component";
import { AppPageHeaderComponent } from "./layout/app-page-header/app-page-header.component";
import { AppDesktopMenuComponent } from "./layout/app-desktop-menu/app-desktop-menu.component";
import { AppSearchAreaComponent } from "./layout/app-search-area/app-search-area.component";
import { AppFooterComponent } from "./layout/app-footer/app-footer.component";
import { AppScrollToTopComponent } from "./layout/app-scroll-to-top/app-scroll-to-top.component";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { AppProductPaginatorComponent } from "./app-products/app-product-paginator/app-product-paginator.component";
import { AppProductSearchComponent } from "./app-products/app-product-search/app-product-search.component";

import { AppCategoriesCarouselComponent } from "./app-products/app-categories-carousel/app-categories-carousel.component";
import { AvatarModule } from "ngx-avatars";
import { MatListModule } from "@angular/material/list";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { AppCartComponent } from "./app-cart/app-cart.component";
import { AppProductDescriptionComponent } from "./app-products/app-product-description/app-product-description.component";
import { PagenotfoundComponent } from "./pagenotfound/pagenotfound.component";

@NgModule({
    declarations: [
        AppComponent,
        TwoDigitDecimaNumberDirective,
        AppProductsComponent,
        AppOrderComponent,
        AppLocationLandingPageComponent,
        AppPreloaderComponent,
        AppMobileMenuComponent,
        AppPageHeaderComponent,
        AppDesktopMenuComponent,
        AppSearchAreaComponent,
        AppFooterComponent,
        AppScrollToTopComponent,
        AppCategoriesCarouselComponent,
        AppCartComponent,
        AppProductDescriptionComponent,
        PagenotfoundComponent,
        AppProductPaginatorComponent,
        AppProductSearchComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        MatStepperModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        HttpClientModule,
        MatCheckboxModule,
        MatDialogModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatOptionModule,
        MatInputModule,
        MatTabsModule,
        MatRadioModule,
        NgSelectModule,
        ToastrModule.forRoot(),
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.wanderingCubes,
            backdropBackgroundColour: "rgba(0,0,0,0.1)",
            backdropBorderRadius: "8px",
            primaryColour: "#038bce",
            secondaryColour: "#3e67ce",
            tertiaryColour: "#a25bce",
        }),
        SlickCarouselModule,
        AvatarModule,
        MatListModule,
        MatToolbarModule,
        MatSidenavModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        AvatarModule,
        AvatarModule,
        AvatarModule,
    ],
    providers: [
        { provide: MAT_DIALOG_DATA, useValue: {} },
        TableCommunicationService,
        DatePipe,
        DecimalPipe,
        FormGroupDirective,
        MatDialog,
    ],
    bootstrap: [AppComponent],
    exports: [],
})
export class AppModule {}
