import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
    selector: "app-app-categories-carousel",
    templateUrl: "./app-categories-carousel.component.html",
    styleUrls: ["./app-categories-carousel.component.css"],
})
export class AppCategoriesCarouselComponent implements OnInit {
    @Input() categoriesCarousel: any = null;
    @Output() categoryChange: EventEmitter<number> = new EventEmitter<number>();

    constructor() {}

    ngOnInit(): void {}

    slideConfig = {
        centerMode: false,
        //centerMode: false,
        //centerPadding: '10px',
        //dots: true,
        /* Just changed this to get the bottom dots navigation */
        infinite: false,
        speed: 400,
        arrows: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        //swipeToSlide: true,
        //easing : 'linear',
        variableWidth: true,
    };

    selectedCategory: any;

    slickInit(e) {}

    breakpoint(e) {}

    afterChange(e) {}

    beforeChange(e) {}

    ChangeCategory(slide) {
        this.categoryChange.emit(slide.uid);
    }
}
