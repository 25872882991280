import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
    selector: "app-app-product-paginator",
    templateUrl: "./app-product-paginator.component.html",
    styleUrls: ["./app-product-paginator.component.css"],
})
export class AppProductPaginatorComponent implements OnInit {
    @Input() page = 1;
    @Input() totalPages = 1;

    @Output() pageChange = new EventEmitter<number>();

    constructor() {}

    ngOnInit(): void {}

    pageUpdate(update: -1 | 1) {
        if (this.page === 1 && update === -1) {
            return;
        }

        if (this.page === this.totalPages && update === 1) {
            return;
        }

        this.page += update;
        this.pageChange.emit(this.page);
    }

    firstPage() {
        if (this.page === 1) {
            return;
        }

        this.page = 1;
        this.pageChange.emit(this.page);
    }

    lastPage() {
        if (this.page === this.totalPages) {
            return;
        }

        this.page = this.totalPages;
        this.pageChange.emit(this.page);
    }
}
