<div class="mobile-menu" style="min-height: 10px">
    <mat-toolbar class="fixed-header">
        <button (click)="closeSideNav()" style="border: none">
            <img
                src="/assets/images/digital_menu_logo_eng.png"
                alt="logo"
                style="width: 150px"
            />
        </button>
        <span class="flexExpand"></span>
        <button mat-icon-button (click)="CartSideNav()">
            <!--<mat-icon style="color: #009675">add_shopping_cart</mat-icon>-->
            <i
                class="icofont-heart"
                style="color: #009675; font-size: 24px"
            ></i>
            <div *ngIf="shoppingCounter > 0">
                <div class="cart_value">{{ shoppingCounter }}</div>
            </div>
        </button>
        <!--        <button mat-icon-button (click)="MenuSideNav()" style="margin-right: 10px">-->
        <!--            <mat-icon style="color: #009765">menu</mat-icon>-->
        <!--        </button>-->
    </mat-toolbar>
</div>
