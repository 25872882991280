<ngx-slick-carousel
    class="carousel"
    #slickModal="slick-carousel"
    [config]="slideConfig"
    (init)="slickInit($event)"
    (breakpoint)="breakpoint($event)"
    (afterChange)="afterChange($event)"
    (beforeChange)="beforeChange($event)"
>
    <div ngxSlickItem *ngFor="let category of categoriesCarousel" class="slide">
        <!--<a class="category-link">{{slide.name}}</a>-->
        <!--<button class="category-link" (click)="ChangeCategory(slide)">{{slide.name}}</button>-->
        <div class="btn-group">
            <div *ngIf="category == categoriesCarousel[0]; else elseBlock">
                <button
                    class="first-category-link"
                    (click)="ChangeCategory(category)"
                >
                    {{ category.name }}
                </button>
            </div>
            <ng-template #elseBlock
                ><button
                    class="category-link"
                    (click)="ChangeCategory(category)"
                >
                    {{ category.name }}
                </button></ng-template
            >
            <!--<button class="category-link" (click)="ChangeCategory(slide)">{{slide.name}}</button>-->
        </div>
    </div>
</ngx-slick-carousel>
