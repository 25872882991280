import { Component, OnInit, Input, TemplateRef } from "@angular/core";
import { CartService } from "../services/cart.service";
import { CategoryModel } from "../types/category.model";
import { ProductCartModel, ProductModel } from "../types/product.model";
import { ApiResponseModel } from "../shared/entities/api-response.model";
import { LocationService } from "../services/location.service";
import { finalize, mergeMap, switchMap, map, tap } from "rxjs/operators";

@Component({
    selector: "app-app-products",
    templateUrl: "./app-products.component.html",
    styleUrls: ["./app-products.component.css"],
})
export class AppProductsComponent implements OnInit {
    page = 1;
    perPage = 20;
    totalPages = 1;

    @Input() locationUid: string;

    first_categ: any;

    products_data: Array<ProductModel> = [];

    //Counter for every category
    product_on_category_counter: number = 0;
    total_counter: number = 0;

    public cartItem: Array<ProductCartModel> = [];
    public itemInLocalStorage: boolean;
    public currentProductMenu: ProductModel;
    public setUpdateButton: boolean = false;
    public disabledButton: boolean;
    public lastCategoryUid?: string;
    public searchValue?: string;

    public showMode: string = "list";
    public categoryLoading: boolean = true;
    public data: any;
    public categories: any = [];

    Component1Data: any;

    constructor(
        private cartService: CartService,
        private locationService: LocationService,
    ) {
        cartService.counterProductsInCart.subscribe((res: any) => {
            this.Component1Data = res;
        });
    }

    onPageUpdate(page: number) {
        this.page = page;
        this.getProducts(
            this.locationUid,
            this.lastCategoryUid,
            page,
            this.searchValue,
        );
    }

    ngOnInit() {
        // Get Categories and products from API
        this.getCategoriesAndProducts().subscribe((data) => (this.data = data));
    }

    public getCategoriesAndProducts() {
        return this.locationService.getCategories(this.locationUid).pipe(
            switchMap((categories) =>
                this.locationService
                    .getProductsPerCategory(
                        this.locationUid,
                        categories.payload[0].uid,
                        this.perPage,
                    )
                    .pipe(
                        map((products) =>
                            this.mapCategoriesAndData(categories, products),
                        ),
                    ),
            ),
            tap(() => (this.categoryLoading = false)),
        );
    }

    public mapCategoriesAndData(categories, products) {
        this.lastCategoryUid = categories.payload[0].uid;
        this.categories = categories.payload;
        products.payload.data.forEach((product) => {
            product.quantity = 0;
            product.price = product.productLocations[0].price;
        });
        this.products_data = products.payload.data;
        this.totalPages = products.payload.last_page;
        this.first_categ = this.products_data;
        this.product_on_category_counter = this.products_data.length;
        this.total_counter = products.payload.total;
        this.currentProductMenu = this.products_data[0];
    }

    public changeModeTo(showMode: string) {
        // Change view mode of products
        this.showMode = showMode;
    }

    countChange(event) {
        this.categories.forEach((category) => {
            if (category.uid === event) {
                this.page = 1;
                this.getProducts(this.locationUid, category.uid, this.page);
            }
        });
    }

    getProducts(
        locationUid: string,
        categoryUid?: string,
        page = 1,
        searchValue = "",
    ) {
        this.lastCategoryUid = categoryUid;
        this.categoryLoading = true;
        return this.locationService
            .getProductsPerCategory(
                locationUid,
                categoryUid,
                this.perPage,
                page,
                searchValue,
            )
            .pipe(finalize(() => (this.categoryLoading = false)))
            .subscribe((response: ApiResponseModel) => {
                console.log(response);
                response.payload.data.forEach((product) => {
                    product.quantity = 0;
                    product.price = product.productLocations[0].price;
                });
                this.products_data = response.payload.data;
                this.currentProductMenu = this.products_data[0];
                this.product_on_category_counter = this.products_data.length;
                this.total_counter = response.payload.total;
                this.totalPages = response.payload.last_page;
            });
    }

    //////////////////////////////////////////////////////////// Shopping Cart ///////////////////////////////////////////////////////////

    addProductToLocalStorage(product: ProductModel) {
        let counter = 0;
        this.itemInLocalStorage = false;
        //Read the Local Storage
        const order = JSON.parse(
            localStorage.getItem("order"),
        ) as Array<ProductModel>;
        //Check if Local Storage is empty ( if it's empty then add the product)
        if (!order || order.length === 0) {
            this.insertFirstProduct(product);
            this.onSubmit(1);
        } else {
            //Check if the product already exists in Local Storage
            for (let index = 0; index < order.length; index++) {
                //If the item exists then add 1 to the quntity field
                if (order[index].uid == product.uid) {
                    //Increment the quantity of the product by one
                    order[index].quantity = order[index].quantity + 1;
                    this.itemInLocalStorage = true;
                    localStorage.setItem("order", JSON.stringify(order));
                    break;
                }
            }
            if (!this.itemInLocalStorage) {
                this.insertProduct(order, product);
            }
            for (let index = 0; index < order.length; index++) {
                counter = counter + order[index].quantity;
            }
            this.onSubmit(counter);
            this.cartService.changeOrder(order);
        }
        product.quantity = 0;
    }

    insertFirstProduct(product: ProductModel) {
        const newItem = this.createNewProductObject(product);
        this.cartItem.push(newItem);
        localStorage.setItem("order", JSON.stringify(this.cartItem));
        this.cartItem.splice(0, 1);
    }

    insertProduct(order: Array<ProductModel>, product: ProductModel) {
        let newItem = this.createNewProductObject(product);
        order.push(newItem);
        localStorage.setItem("order", JSON.stringify(order));
    }

    createNewProductObject(product: ProductModel) {
        product.quantity = 1;
        return product;
    }

    onSubmit(data: any) {
        this.cartService.counterShoppingCart(data);
    }

    showProductDetails(data: any) {
        this.setUpdateButton = false;
        this.currentProductMenu = data;
        //change Product model to have quantity and hardcoded 0;
        const order = JSON.parse(localStorage.getItem("order"));
        //this.disabledButton = true;
        if (order != null) {
            for (let index = 0; index < order.length; index++) {
                if (this.currentProductMenu.uid == order[index].uid) {
                    this.currentProductMenu.quantity = order[index].quantity;
                    this.setUpdateButton = true;
                    //this.disabledButton = false;
                    break;
                }
            }
        }
    }

    onSearchProduct(searchValue: string) {
        this.searchValue = searchValue;
        this.getProducts(this.locationUid, undefined, this.page, searchValue);
    }
}
