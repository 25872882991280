import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { CartService } from "../services/cart.service";
import { ProductCartModel } from "../types/product.model";

@Component({
    selector: "app-app-cart",
    templateUrl: "./app-cart.component.html",
    styleUrls: ["./app-cart.component.css"],
})
export class AppCartComponent implements OnInit {
    @Output() public sidenavToggle = new EventEmitter();
    cartOrder: Array<ProductCartModel> = [];
    shoppingCounter: number;
    cartTotalCost: number = 0;
    orderPlaced: boolean = false;

    //Get the data in the order(Local Storage) through the CartService (if order empty emptyOrder = true)

    constructor(private cartService: CartService) {
        //Get the order(Array of Product Objects) from the local storage in the first iteration
        cartService.ProductsLocalStorage.subscribe((res: any) => {
            this.cartOrder = res;
        });
        this.cartOrder = JSON.parse(localStorage.getItem("order"));
        //Get the order from the local storage(except the first iteration)
        cartService.ProductsLocalStorage.subscribe((res: any) => {
            this.cartOrder = res;
            this.cartTotalCost = 0;
            if (this.cartOrder != null) {
                this.cartOrder.forEach((product) => {
                    this.cartTotalCost =
                        this.cartTotalCost + product.quantity * product.price;
                });
            }
        });

        //Get the product quantity(counter) from the the order
        this.shoppingCounter = 0;
        if (this.cartOrder != null) {
            this.cartOrder.forEach((product) => {
                this.shoppingCounter = this.shoppingCounter + product.quantity;
            });
        }

        //Get the  product quantity(counter) from the the order(except the first iteration)
        cartService.counterProductsInCart.subscribe((res: any) => {
            this.shoppingCounter = res;
        });

        cartService.orderPlaced.subscribe((res: any) => {
            this.orderPlaced = res;
        });
    }

    ngOnInit(): void {
        //Calculate the Totoal cost of cart at the beginning
        this.cartTotalCost = 0;
        this.cartOrder = JSON.parse(localStorage.getItem("order"));
        if (this.cartOrder != null) {
            this.cartOrder.forEach((product) => {
                this.cartTotalCost =
                    this.cartTotalCost + product.quantity * product.price;
            });
        }
    }

    plusButtonQuantity(product: ProductCartModel, index: number) {
        product.quantity++;
        this.shoppingCounter++;
        this.cartOrder[index].quantity = product.quantity;
        //Save order in local storage and send the order(cartOrder) and the counter(shoppingCounter) to the other components with Service
        localStorage.setItem("order", JSON.stringify(this.cartOrder));
        this.cartService.counterShoppingCart(this.shoppingCounter);
        this.cartService.changeOrder(this.cartOrder);
        this.cartTotalCost = 0;
        this.cartOrder.forEach((product) => {
            this.cartTotalCost =
                this.cartTotalCost + product.quantity * product.price;
        });
    }

    minusButtonQuantity(product: ProductCartModel, index: number): void {
        product.quantity--;
        this.shoppingCounter--;
        //Eliminate a product if quantity reaches 0
        if (product.quantity === 0) {
            this.cartOrder.splice(index, 1);
            //If cart is empty set order to null
            if (this.cartOrder.length === 0) {
                this.cartOrder = null;
            }
        } else {
            this.cartOrder[index].quantity = product.quantity;
        }
        //Save order in local storage and send the order(cartOrder) and the counter(shoppingCounter) to the other components with Service
        localStorage.setItem("order", JSON.stringify(this.cartOrder));
        this.cartService.counterShoppingCart(this.shoppingCounter);
        this.cartService.changeOrder(this.cartOrder);
        this.cartTotalCost = 0;
        if (this.cartOrder != null) {
            if (this.cartOrder.length > 0) {
                this.cartOrder.forEach((product) => {
                    this.cartTotalCost =
                        this.cartTotalCost + product.quantity * product.price;
                });
            }
        }
    }

    //To be changed in porduction
    placeOrder() {
        localStorage.clear();
        //Shopping counter = 0
        this.cartService.counterShoppingCart(0);
        //Refresh items in local storage
        let order = JSON.parse(localStorage.getItem("order"));
        this.cartService.changeOrder(order);
        this.orderPlaced = true;
    }

    public onToggleSidenav = () => {
        this.sidenavToggle.emit();
        this.orderPlaced = false;
        this.cartService.changeSidenavStatus(false);
    };
}
