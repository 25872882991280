<div style="overflow: hidden">
    <!--chang this ngIf to what you cartOrder null or diff null-->
    <div *ngIf="cartOrder && cartOrder.length > 0">
        <div
            class="header"
            style="
                padding-bottom: 20px;
                padding-top: 20px;
                background-color: rgb(0, 150, 117);
                margin-bottom: 10px;
            "
        >
            <div
                class="d-flex justify-content-center"
                style="padding-left: 10px; padding-right: 10px"
            >
                <div class="col-3">
                    <div
                        class="header-quantity"
                        style="
                            font-size: 14px;
                            color: white;
                            text-align: center;
                            margin-left: 10px;
                        "
                    >
                        Cantitate
                    </div>
                </div>
                <div class="col-5">
                    <div
                        class="header-product"
                        style="
                            font-size: 14px;
                            color: white;
                            text-align: center;
                            margin-left: 3px;
                        "
                    >
                        Produs
                    </div>
                </div>
                <div class="col-2">
                    <div style="text-align: center">
                        <!--<div class="header-product-cost" style="font-size: 14px;color:white;text-align: end;">Product Cost</div>-->
                        <div
                            class="header-product-cost"
                            style="font-size: 14px; color: white"
                        >
                            Cost
                        </div>
                        <div
                            class="header-product-cost"
                            style="font-size: 14px; color: white"
                        >
                            Produs
                        </div>
                    </div>
                </div>
                <div class="col-2">
                    <!--<div class="header-total-cost" style="font-size: 14px;color:white;text-align: start;margin-left: 8px;">Total Cost</div>-->
                    <div style="text-align: center">
                        <!--<div class="header-product-cost" style="font-size: 14px;color:white;text-align: end;">Product Cost</div>-->
                        <div
                            class="header-product-cost"
                            style="font-size: 14px; color: white"
                        >
                            Total
                        </div>
                        <div
                            class="header-product-cost"
                            style="font-size: 14px; color: white"
                        >
                            Cost
                        </div>
                    </div>
                </div>
                <!--2,4,3,3 old col values-->
            </div>
        </div>
        <div class="cart-content">
            <div *ngFor="let produs of cartOrder; let i = index">
                <div class="shop-product-wrap grid row">
                    <div
                        class="col-10 align-self-start"
                        style="
                            width: 100%;
                            padding-left: 20px;
                            padding-right: 20px;
                        "
                    >
                        <div
                            class="product-item"
                            style="padding: 10px; margin-bottom: 10px"
                        >
                            <div class="d-flex justify-content-start">
                                <div class="col-1" style="width: 9.3333333%">
                                    <button
                                        class="button"
                                        (click)="minusButtonQuantity(produs, i)"
                                        style="
                                            background-color: #009675;
                                            border: none;
                                            border-radius: 4px;
                                            padding: 1px 9px;
                                            color: white;
                                            height: 33px;
                                        "
                                    >
                                        <i class="fa fa-minus"></i>
                                    </button>
                                </div>
                                <div class="col-1" style="text-align: center">
                                    <div
                                        class="sing-price"
                                        style="font-size: 14px; margin-top: 5px"
                                    >
                                        {{ produs.quantity }}
                                    </div>
                                </div>
                                <div class="col-1" style="width: 9.3333333%">
                                    <button
                                        class="button"
                                        (click)="plusButtonQuantity(produs, i)"
                                        style="
                                            background-color: #009675;
                                            border: none;
                                            border-radius: 4px;
                                            padding: 1px 9px;
                                            color: white;
                                            height: 33px;
                                        "
                                    >
                                        <i class="fa fa-plus"></i>
                                    </button>
                                </div>

                                <div class="col-5">
                                    <div *ngIf="produs.name.length <= 20">
                                        <div
                                            class="prod-name"
                                            style="
                                                font-size: 14px;
                                                margin-left: 3px;
                                            "
                                        >
                                            {{ produs.name }}
                                        </div>
                                    </div>
                                    <div *ngIf="produs.name.length > 20">
                                        <div
                                            class="prod-name"
                                            style="
                                                font-size: 12px;
                                                margin-left: 3px;
                                            "
                                        >
                                            {{ produs.name }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div
                                        class="mult-price-quant"
                                        style="font-size: 14px"
                                    >
                                        {{ produs.price | number: "1.2-2" }}
                                    </div>
                                    <span
                                        style="
                                            font-size: 14px;
                                            color: black;
                                            font-weight: normal;
                                        "
                                        >RON</span
                                    >
                                </div>
                                <div class="col-2">
                                    <div
                                        class="mult-price-quant"
                                        style="
                                            font-size: 14px;
                                            color: rgb(0, 150, 117);
                                            font-weight: 600;
                                        "
                                    >
                                        {{
                                            produs.quantity * produs.price
                                                | number: "1.2-2"
                                        }}
                                    </div>
                                    <span
                                        style="
                                            font-size: 14px;
                                            color: black;
                                            font-weight: normal;
                                        "
                                        >RON</span
                                    >
                                </div>
                                <!-- the delete button
                                                        <div class="col-1" style="height: max-content;">
                                                            <button class="ceva"></button>
                                                        </div> -->
                            </div>

                            <!--THE GOOOOOOOOOOOOOOOOOOOOOOOOOOD STUUUUUUFFFFF
                          <div *ngFor="let produs of cartOrder;let i = index">
                              <div class="shop-product-wrap grid row">
                                  <div class="col-12" style="width: 100%;margin-left: 5px;padding-right: 22px;" >
                                      <div class="product-item" style="padding-top:10px;padding-bottom: 10px;margin-bottom: 10px;">
                                          <div class="d-flex justify-content-start">
                                              <button class="button"   (click)="minusButtonQuantity(produs,i)" style="background-color:#009675;border: none;border-radius: 4px;padding: 1px 9px;color:white;height: 33px;"><i class="fa fa-minus" ></i></button>
                                              <div class="sing-price" style="font-size: 14px;margin-top:5px;margin-left: 4px;margin-right: 4px;">{{produs.quantity}}</div>
                                              <button class="button"   (click)="plusButtonQuantity(produs,i)" style="background-color:#009675;border: none;border-radius: 4px;padding: 1px 9px;color:white;height: 33px;"><i class="fa fa-plus" ></i></button>
                                              <div class="col-5">
                                                  <div *ngIf="produs.name.length < 20">
                                                      <div class="prod-name" style="font-size: 14px;">{{produs.name}}</div>
                                                  </div>
                                                  <div *ngIf="produs.name.length > 20">
                                                      <div class="prod-name" style="font-size: 12px;">{{produs.name}}</div>
                                                  </div>
                                              </div>
                                              <div class="col-2">
                                                  <div class="mult-price-quant" style="font-size: 14px;margin-right: 10px;">{{produs.price}}.00 RON</div>
                                              </div>
                                              <div class="col-2">
                                                  <div class="mult-price-quant" style="font-size: 14px;color:rgb(0, 150, 117);font-weight: 600;">{{produs.quantity*produs.price}}.00 <span style="font-size: 14px;color: black;font-weight: normal;">RON</span></div>
                                              </div>

                                          </div>
              -->

                            <!--
                                                                  <div class="row justify-content-start">
                                                                      <div class="col-1 align-self-start">
                                                                          <button class="btn btn-primary btn-square-md" style="background-color : rgb(0, 150, 117); border : none;font-size: 5px;" (click)="minusButtonQuantity(produs,i)"><i class="fa fa-minus"></i></button>
                                                                      </div>
                                                                      <div class="col-1 align-self-start">
                                                                          <div class="sing-price" style="font-size: 10px;margin-top:5px;">{{produs.quantity}}</div>
                                                                      </div>
                                                                      <div class="col-1">
                                                                          <button class="btn btn-primary btn-square-md" style="background-color : rgb(0, 150, 117); border : none;font-size: 5px;margin-left:-18px;" (click)="plusButtonQuantity(produs,i)"><i class="fa fa-plus"></i></button>
                                                                      </div>

                                                                      <div class="col-5">
                                                                          <div *ngIf="produs.name.length < 20">
                                                                              <div class="prod-name" style="font-size: 12px;">{{produs.name}}</div>
                                                                          </div>
                                                                          <div *ngIf="produs.name.length > 20">
                                                                              <div class="prod-name" style="font-size: 10px;">{{produs.name}}</div>
                                                                          </div>
                                                                      </div>
                                                                      <div class="col-2">
                                                                          <div class="mult-price-quant" style="font-size: 10px;">{{produs.price}}.00 RON</div>
                                                                      </div>
                                                                      <div class="col-1">
                                                                          <div class="mult-price-quant" style="font-size: 10px;">{{produs.quantity*produs.price}}.00 RON</div>
                                                                      </div>
                                                                  </div>
                                                                  -->
                        </div>
                    </div>
                </div>
            </div>

            <div class="cart-scr-bottom">
                <br />
                <ul style="padding-left: 16px">
                    <li>
                        <div class="title">Total Wishlist</div>
                        <div
                            class="price"
                            style="
                                color: rgb(0, 150, 117);
                                font-size: 20px;
                                font-weight: 600;
                            "
                        >
                            {{ cartTotalCost | number: "1.2-2" }} RON
                        </div>
                    </li>
                    <li>
                        <button
                            class="food-btn"
                            (click)="placeOrder()"
                            style="margin-bottom: 40px"
                        >
                            <span>Golire wishlist</span>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!cartOrder">
    <p
        style="
            text-align: center;
            margin-top: 200px;
            color: rgb(160, 160, 160);
            font-size: 24px;
            font-family: Monaco;
        "
    >
        Nu sunt produse in Wishlist
    </p>
</div>

<div style="text-align: center">
    <!--if you want to show as link<a href="#" style="color:rgb(160,160,160);font-size: 15px;margin-bottom: 100px;" (click)="onToggleSidenav()">Go back to products</a>-->
    <button
        style="
            background-color: #009675;
            border: none;
            border-radius: 12px;
            padding: 8px 25px;
            color: white;
        "
        (click)="onToggleSidenav()"
    >
        Intoarce-te la Meniu
    </button>
</div>
