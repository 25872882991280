<!--Change percentage for lower image-->
<section
    class="page-header background-image style-2 img-fluid"
    [ngStyle]="{ 'background-image': 'url(' + locationBackground + ')' }"
>
    <ngx-avatars
        class="avatar"
        src="{{ companyLogo }}"
        size="100"
        [round]="true"
    ></ngx-avatars>
</section>
<!--
<section class="page-header style-2 img-fluid" style="background-image:url('/assets/images/company_background.jpg');">
    <ngx-avatar class="avatar" src="/assets/images/company_logo.png" size="100" [round]="true"></ngx-avatar>
</section>
-->
