import { Component, EventEmitter, OnInit, Output } from "@angular/core";

@Component({
    selector: "app-app-product-search",
    templateUrl: "./app-product-search.component.html",
    styleUrls: ["./app-product-search.component.css"],
})
export class AppProductSearchComponent implements OnInit {
    @Output() search = new EventEmitter<string>();

    searchItem(searchValue: string) {
        this.search.emit(searchValue);
    }

    constructor() {}

    ngOnInit(): void {}
}
