import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { finalize } from "rxjs/operators";
import { ApiResponseModel } from "./shared/entities/api-response.model";
import { LocationService } from "./services/location.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of } from "rxjs";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
    public loading: boolean = true;
    title = "dispecerat";
    sideMenuContext: any;
    a: any;

    constructor(public locationService: LocationService) {}

    ngOnInit(): void {}

    changeMenuOrCart(event) {
        this.sideMenuContext = event;
    }
}
