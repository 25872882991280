import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-app-scroll-to-top",
    templateUrl: "./app-scroll-to-top.component.html",
    styleUrls: ["./app-scroll-to-top.component.css"],
})
export class AppScrollToTopComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
