import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-app-desktop-menu",
    templateUrl: "./app-desktop-menu.component.html",
    styleUrls: ["./app-desktop-menu.component.css"],
})
export class AppDesktopMenuComponent implements OnInit {
    constructor() {}

    public miniCartStatus = false;

    ngOnInit(): void {}

    public toggleMiniCartStatus(): void {
        this.miniCartStatus = !this.miniCartStatus;
    }
}
