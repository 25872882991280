import { Component, OnInit, Input } from "@angular/core";
import { CompanyModel } from "src/app/types/company.model";
import { ScheduleModel } from "src/app/types/schedule.model";

@Component({
    selector: "app-app-footer",
    templateUrl: "./app-footer.component.html",
    styleUrls: ["./app-footer.component.css"],
})
export class AppFooterComponent implements OnInit {
    @Input() companyContactData: CompanyModel;
    @Input() schedule: ScheduleModel;
    @Input() address: string;
    @Input() isAvailable: boolean = false;

    constructor() {}

    ngOnInit(): void {}
}
