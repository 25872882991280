<div class="mid-container">
   <button class="button" [disabled]="page <= 1" (click)="firstPage()">
        <i class="arrows-icon left"></i>
    </button>
    <button class="button" [disabled]="page <= 1" (click)="pageUpdate(-1)">
        <i class="arrow-icon left"></i>
    </button>
    <span>{{ page }} / {{ totalPages }}</span>
   <button class="button" (click)="pageUpdate(1)" [disabled]="page >= totalPages">
        <i class="arrow-icon right"></i>
    </button>
    <button class="button" [disabled]="page >= totalPages" (click)="lastPage()">
        <i class="arrows-icon right"></i>
    </button>
</div>
