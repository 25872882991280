<div class="offcanvas-header" style="background-color: #009675">
    <button
        type="button"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
        (click)="resetQuantity()"
    ></button>
</div>

<div class="offcanvas-body small">
    <img
        src="{{ currentProductMenu.defaultImageUrl }}"
        style="
            object-fit: contain;
            min-width: 100%;
            max-width: 100%;
            height: 147px;
        "
    />
    <br />
    <br />
    <span class="cat-name" style="color: black; font-size: 18px">{{
        currentProductMenu.name
    }}</span>
    <div *ngIf="currentProductMenu.short_description">
        <!--        <span class="cat-name">{{ currentProductMenu.short_description }}</span>-->
        {{ currentProductMenu.short_description }}
    </div>
    <div *ngIf="!currentProductMenu.short_description">
        Nu exista descriere scurta pentru produs
    </div>
    <br />
    <span class="product-price"
        >{{ currentProductMenu.price | number: "1.2-2" }} RON</span
    >
    <br />
    <br />
    <!--<a (click)="addProductToLocalStorage(produs)"><i class="icofont-cart-alt"></i></a>-->
    <div class="form-check-inline">
        <button class="plus-minus-button" (click)="minusSignProductDetails()">
            <i class="fa fa-minus"></i>
        </button>
    </div>
    <span style="padding-right: 12px">{{
        !setUpdateButton && currentProductMenu.quantity === 0
            ? 1
            : currentProductMenu.quantity
    }}</span>
    <div class="form-check-inline">
        <button class="plus-minus-button" (click)="plusSignProductDetails()">
            <i class="fa fa-plus"></i>
        </button>
    </div>

    <div *ngIf="!setUpdateButton" style="position: relative">
        <!--<button class="add-button" [disabled] = "disabledButton" [ngClass]="(disabledButton==true)?'disabledB':'activeB'" data-bs-dismiss="offcanvas" (click)="addProductsToLocalStorageProductsDetail2(currentProductMenu)">Add to cart<i class="icofont-cart-alt"></i></button>-->
        <button
            class="add-button"
            data-bs-dismiss="offcanvas"
            (click)="addProductsToLocalStorage3(currentProductMenu)"
        >
            <i class="icofont-heart"></i>
            Adauga
        </button>
    </div>
    <div *ngIf="setUpdateButton" style="position: relative">
        <button
            class="add-button"
            data-bs-dismiss="offcanvas"
            (click)="updateProductsToLocalStorage3(currentProductMenu)"
        >
            <i class="icofont-heart"></i>
            Actualizeaza
        </button>
    </div>
    <br />
    <br />
    <br />
    <div *ngIf="currentProductMenu.longDescription">
        <span class="cat-name" style="margin-bottom: 100px">{{
            currentProductMenu.longDescription
        }}</span>
    </div>
    <!--    <div *ngIf="!currentProductMenu.longDescription" style="margin-bottom: 100px">-->
    <!--        Nu exista descriere lunga pentru produs-->
    <!--    </div>-->
</div>
