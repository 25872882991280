import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { HttpApiService } from "../shared/services/http-api.service";
import { ApiResponseModel } from "../shared/entities/api-response.model";
import { LocationModel } from "../types/location.model";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: "root",
})
export class LocationService {
    constructor(
        public httpApiService: HttpApiService,
        private http: HttpClient,
        private router: Router,
    ) {}

    getLocationData(locationUid?: string): Observable<ApiResponseModel> {
        // return this.httpApiService.httpGet('digital-menu/getLocationData/84cec4b7-09af-42e7-b02c-082ed80105d0');
        return this.httpApiService.httpGet(
            "digital-menu/getLocationData/" + locationUid,
        );
    }

    getLocation(locationUid: string): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet(
            "digital-menu/getLocation/" + locationUid,
        );
    }

    getCategories(locationUid: string): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet(
            "digital-menu/getCategories/" + locationUid,
        );
    }

    getProductsPerCategory(
        locationUid: string,
        categoryUid?: string,
        perPage = 10,
        page = 1,
        searchText = "",
    ): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet(
            "digital-menu/getProductPerCategory/" +
                locationUid +
                "/" +
                categoryUid,
            { perPage, page, searchText: searchText },
        );
    }
}
