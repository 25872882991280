<!--<h1>Denumire locatie: {{locationName}}</h1>-->

<!--Loading screen-->
<div *ngIf="loading">
    <ngx-loading [show]="loading"></ngx-loading>
</div>

<div *ngIf="!loading">
    <app-app-mobile-menu
        (menuAndCartChange)="MenuChange($event)"
        (sidenavToggle)="sidenav.toggle()"
    ></app-app-mobile-menu>

    <mat-sidenav-container>
        <mat-sidenav
            #sidenav
            role="navigation"
            position="end"
            [autoFocus]="false"
            [ngClass]="menu == true ? 'width-60' : 'width-100'"
        >
            <div *ngIf="menu">
                <mat-nav-list>
                    <a mat-list-item>
                        <span class="nav-caption">Termeni si conditii</span>
                    </a>
                    <a mat-list-item>
                        <span class="nav-caption"
                            >Polita de confidentaliatet</span
                        >
                    </a>
                    <a mat-list-item>
                        <span class="nav-caption">Alergeni</span>
                    </a>
                    <a mat-list-item>
                        <span class="nav-caption">Contact</span>
                    </a>
                </mat-nav-list>
            </div>
            <div *ngIf="!menu">
                <app-app-cart (sidenavToggle)="sidenav.toggle()"></app-app-cart>
            </div>
        </mat-sidenav>

        <!-- preloader start here -->
        <app-app-preloader></app-app-preloader>
        <!-- preloader ending here -->

        <!-- search area -->
        <app-app-search-area></app-app-search-area>
        <!-- search area -->

        <!-- desktop menu start here -->
        <app-app-desktop-menu></app-app-desktop-menu>
        <!-- desktop menu ending here -->

        <!-- Page Header Section Start Here -->
        <app-app-page-header
            [companyLogo]="locationTest.company.logoUrl"
            [locationBackground]="locationTest.company.bannerUrl"
        ></app-app-page-header>
        <!-- Page Header Section Ending Here -->

        <!-- Page Section Start Here -->
        <app-app-products [locationUid]="locationUid"></app-app-products>
        <!-- Page Section Ending Here -->

        <!-- footer area start -->
        <app-app-footer
            [isAvailable]="locationTest.available"
            [companyContactData]="locationTest.company"
            [schedule]="locationTest.schedule"
            [address]="locationTest.fullAddress"
        ></app-app-footer>
        <!-- footer area ends -->

        <!-- scrollToTop start here -->
        <app-app-scroll-to-top></app-app-scroll-to-top>
        <!-- scrollToTop ending here -->
    </mat-sidenav-container>
</div>
