<div class="search-area">
    <div class="search-input">
        <div class="search-close">
            <span></span>
            <span></span>
        </div>
        <form>
            <input type="text" name="text" placeholder="*Search Here......." />
            <button class="search-btn">
                <span class="serch-icon"><i class="icofont-search-2"></i></span>
            </button>
        </form>
    </div>
</div>
