import { Component, OnInit, Input } from "@angular/core";
import { CartService } from "src/app/services/cart.service";
import { ProductModel } from "src/app/types/product.model";

@Component({
    selector: "app-app-product-description",
    templateUrl: "./app-product-description.component.html",
    styleUrls: ["./app-product-description.component.css"],
})
export class AppProductDescriptionComponent implements OnInit {
    @Input() currentProductMenu: any;
    @Input() setUpdateButton: boolean;
    //@Input() disabledButton : boolean;

    Component1Data: any;
    constructor(private cartService: CartService) {
        cartService.counterProductsInCart.subscribe((res: any) => {
            this.Component1Data = res;
        });
    }

    public itemInLocalStorage: boolean;

    ngOnInit(): void {
        console.log(this.currentProductMenu);
    }

    plusSignProductDetails(): void {
        if (!this.setUpdateButton && this.currentProductMenu.quantity === 0) {
            this.currentProductMenu.quantity = 1;
        }
        this.currentProductMenu.quantity++;
    }

    minusSignProductDetails(): void {
        if (this.currentProductMenu.quantity > 0) {
            this.currentProductMenu.quantity--;
        }
    }

    addProductsToLocalStorageProductsDetail2(product) {
        let counter = 0;
        var index;
        this.itemInLocalStorage = false;
        var order = JSON.parse(localStorage.getItem("order"));
        //Local storage not null
        if (product.quantity !== 0 && this.setUpdateButton !== false) {
            if (order != null && order.length > 0) {
                //Check if product is on local storage
                order.forEach((currentProduct, i) => {
                    if (product.uid == currentProduct.uid) {
                        index = i;
                        this.itemInLocalStorage = true;
                    }
                });
                //Product is not in local storage so add it
                if (this.itemInLocalStorage == false && product.quantity != 0) {
                    order.push(product);
                }
                //Product is in local storage
                else {
                    //Product Quantity is 0
                    if (product.quantity === 0) {
                        //Check if product need to be elimnated or order = null
                        if (order.length < 2) {
                            order = null;
                        } else {
                            order.splice(index, 1);
                        }
                    }
                    //Product quantity != 0 (update product quantity)
                    else {
                        order[index].quantity = product.quantity;
                    }
                }
            }
            //Local storage null
            else {
                //If quantity != 0 add product
                if (product.quantity != 0) {
                    order = [product];
                }
            }
            //Add order to local storage and pass counter and order to service
        }
        localStorage.setItem("order", JSON.stringify(order));
        var order = JSON.parse(localStorage.getItem("order"));
        if (order != null && order.length > 0) {
            for (let index = 0; index < order.length; index++) {
                counter = counter + order[index].quantity;
            }
        }
        this.cartService.counterShoppingCart(counter);
        this.cartService.changeOrder(order);
        this.currentProductMenu.quantity = 0;
    }

    addProductsToLocalStorage3(product) {
        let counter = 0;
        var order = JSON.parse(localStorage.getItem("order"));
        //Check if quantity is not 0
        if (product.quantity === 0) {
            product.quantity = 1;
        }
        if (product.quantity !== 0) {
            //If local storage si not null
            if (order != null && order.length > 0) {
                //Add item in local storage
                order.push(product);
            } else {
                order = [product];
            }
        }
        localStorage.setItem("order", JSON.stringify(order));
        var order = JSON.parse(localStorage.getItem("order"));
        if (order != null && order.length > 0) {
            for (let index = 0; index < order.length; index++) {
                counter = counter + order[index].quantity;
            }
        }
        this.cartService.counterShoppingCart(counter);
        this.cartService.changeOrder(order);
        this.currentProductMenu.quantity = 0;
    }

    updateProductsToLocalStorage3(product) {
        let counter = 0;
        var index;
        var order = JSON.parse(localStorage.getItem("order"));
        order.forEach((currentProduct, i) => {
            if (product.uid == currentProduct.uid) {
                index = i;
            }
        });
        if (product.quantity === 0) {
            //Check if product need to be elimnated or order = null
            if (order.length < 2) {
                order = null;
            } else {
                order.splice(index, 1);
            }
        } else {
            order[index].quantity = product.quantity;
        }
        localStorage.setItem("order", JSON.stringify(order));
        var order = JSON.parse(localStorage.getItem("order"));
        if (order != null && order.length > 0) {
            for (let index = 0; index < order.length; index++) {
                counter = counter + order[index].quantity;
            }
        }
        this.cartService.counterShoppingCart(counter);
        this.cartService.changeOrder(order);
        this.currentProductMenu.quantity = 0;
    }

    resetQuantity(): void {
        this.currentProductMenu.quantity = 0;
        //this.disabledButton = true;
    }
}
