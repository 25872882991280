<footer
    class="padding-tb"
    style="background-image: url(assets/css/bg-image/footer-bg.jpg)"
>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                <div class="contant-info">
                    <h5>Contact</h5>
                    <ul class="info">
                        <li>
                            <i
                                class="fas fa-home"
                                style="background-color: #009675"
                            ></i>
                            <!--<span>Suite 02 Level Tropical Center</span>-->
                            <span>{{ address }}</span>
                        </li>
                        <li>
                            <i
                                class="fas fa-phone"
                                style="background-color: #009675"
                            ></i>
                            <span>{{ companyContactData.phone }}</span>
                        </li>
                        <li>
                            <a href="javascript:void(0)">
                                <i
                                    class="fas fa-globe-asia"
                                    style="background-color: #009675"
                                ></i>
                                <span>{{ companyContactData.website }}</span>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)">
                                <i
                                    class="fas fa-headphones"
                                    style="background-color: #009675"
                                ></i>
                                <span>{{ companyContactData.email }}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                <div class="opening-time">
                    <h5>Ore de lucru</h5>
                    <ul>
                        <li>
                            <p>
                                <i
                                    class="far fa-clock"
                                    style="color: #009675"
                                ></i
                                >Luni
                            </p>
                            <div *ngIf="schedule.monday.closed === 'true'">
                                <span>Inchis</span>
                            </div>
                            <div *ngIf="schedule.monday.allDay === 'true'">
                                <span>Deschis Toata Ziua</span>
                            </div>
                            <div
                                *ngIf="
                                    schedule.monday.start && schedule.monday.end
                                "
                            >
                                <span
                                    >{{ schedule.monday.start | date: "HH:mm" }}
                                    -
                                    {{
                                        schedule.monday.end | date: "HH:mm"
                                    }}</span
                                >
                            </div>
                        </li>
                        <li>
                            <p>
                                <i
                                    class="far fa-clock"
                                    style="color: #009675"
                                ></i
                                >Marti
                            </p>
                            <div *ngIf="schedule.tuesday.closed === 'true'">
                                <span>Inchis</span>
                            </div>
                            <div *ngIf="schedule.tuesday.allDay === 'true'">
                                <span>Deschis Toata Ziua</span>
                            </div>
                            <div
                                *ngIf="
                                    schedule.tuesday.start &&
                                    schedule.tuesday.end
                                "
                            >
                                <span
                                    >{{
                                        schedule.tuesday.start | date: "HH:mm"
                                    }}
                                    -
                                    {{
                                        schedule.tuesday.end | date: "HH:mm"
                                    }}</span
                                >
                            </div>
                        </li>
                        <li>
                            <p>
                                <i
                                    class="far fa-clock"
                                    style="color: #009675"
                                ></i
                                >Miercuri
                            </p>
                            <div *ngIf="schedule.wednesday.closed === 'true'">
                                <span>Inchis</span>
                            </div>
                            <div *ngIf="schedule.wednesday.allDay === 'true'">
                                <span>Deschis Toata Ziua</span>
                            </div>
                            <div
                                *ngIf="
                                    schedule.wednesday.start &&
                                    schedule.wednesday.end
                                "
                            >
                                <span
                                    >{{
                                        schedule.wednesday.start | date: "HH:mm"
                                    }}
                                    -
                                    {{
                                        schedule.wednesday.end | date: "HH:mm"
                                    }}</span
                                >
                            </div>
                        </li>
                        <li>
                            <p>
                                <i
                                    class="far fa-clock"
                                    style="color: #009675"
                                ></i
                                >Joi
                            </p>
                            <div *ngIf="schedule.thursday.closed === 'true'">
                                <span>Inchis</span>
                            </div>
                            <div *ngIf="schedule.thursday.allDay === 'true'">
                                <span>Deschis Toata Ziua</span>
                            </div>
                            <div
                                *ngIf="
                                    schedule.thursday.start &&
                                    schedule.thursday.end
                                "
                            >
                                <span
                                    >{{
                                        schedule.thursday.start | date: "HH:mm"
                                    }}
                                    -
                                    {{
                                        schedule.thursday.end | date: "HH:mm"
                                    }}</span
                                >
                            </div>
                        </li>
                        <li>
                            <p>
                                <i
                                    class="far fa-clock"
                                    style="color: #009675"
                                ></i
                                >Vineri
                            </p>
                            <div *ngIf="schedule.friday.closed === 'true'">
                                <span>Inchis</span>
                            </div>
                            <div *ngIf="schedule.friday.allDay === 'true'">
                                <span>Deschis Toata Ziua</span>
                            </div>
                            <div
                                *ngIf="
                                    schedule.friday.start && schedule.friday.end
                                "
                            >
                                <span
                                    >{{ schedule.friday.start | date: "HH:mm" }}
                                    -
                                    {{
                                        schedule.friday.end | date: "HH:mm"
                                    }}</span
                                >
                            </div>
                        </li>
                        <li>
                            <p>
                                <i
                                    class="far fa-clock"
                                    style="color: #009675"
                                ></i
                                >Sambata
                            </p>
                            <div *ngIf="schedule.saturday.closed === 'true'">
                                <span>Inchis</span>
                            </div>
                            <div *ngIf="schedule.saturday.allDay === 'true'">
                                <span>Deschis Toata Ziua</span>
                            </div>
                            <div
                                *ngIf="
                                    schedule.saturday.start &&
                                    schedule.saturday.end
                                "
                            >
                                <span
                                    >{{
                                        schedule.saturday.start | date: "HH:mm"
                                    }}
                                    -
                                    {{
                                        schedule.saturday.end | date: "HH:mm"
                                    }}</span
                                >
                            </div>
                        </li>
                        <li>
                            <p>
                                <i
                                    class="far fa-clock"
                                    style="color: #009675"
                                ></i
                                >Duminica
                            </p>
                            <div *ngIf="schedule.sunday.closed === 'true'">
                                <span>Inchis</span>
                            </div>
                            <div *ngIf="schedule.sunday.allDay === 'true'">
                                <span>Deschis Toata Ziua</span>
                            </div>
                            <div
                                *ngIf="
                                    schedule.sunday.start && schedule.sunday.end
                                "
                            >
                                <span
                                    >{{ schedule.sunday.start | date: "HH:mm" }}
                                    -
                                    {{
                                        schedule.sunday.end | date: "HH:mm"
                                    }}</span
                                >
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                <div class="news_letter">
                    <!-- <p>Aboneaza-te la Newsletter</p>
                     <p>Intra in Cont Pentru Noutat si Articole. Nu veti primi spam.</p>
                    <div class="contact-search">
                      <form class="searchForm" method="get" action="0">
                        <input
                          class="searchTerm"
                          placeholder="Introducesti adresa de Email"
                          autocomplete="off"
                        />
                        <input
                          type="submit"
                          class="searchBtn"
                          value="Abonare"
                          style="border-radius: 12px"
                        />
                      </form>
                    </div>-->
                </div>
            </div>
        </div>
    </div>
</footer>

<!-- footer menu start -->
<div class="fotter-header">
    <header>
        <div class="footer-bg">
            <div class="container">
                <div class="header-item">
                    <div class="header-logo">
                        <p>
                            &copy; 2020<a href="javascript:void(0)">FoodBuzz</a
                            >Designed by<a
                                href="https://themeforest.net/user/labartisan"
                                >Labartisan</a
                            >
                        </p>
                    </div>
                    <div class="header-menu d-none d-lg-block">
                        <nav class="primary-menu">
                            <div class="main-menu-area">
                                <ul class="main-menu">
                                    <li>
                                        <a href="javascript:void(0)">Acasa</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)"
                                            >Rezervari</a
                                        >
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)">Contact</a>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <!-- <div class="footer-bottom"></div> -->
</div>
<!-- footer menu ends -->
