import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    OnDestroy,
} from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CartService } from "src/app/services/cart.service";
import { ProductCartModel } from "src/app/types/product.model";

@Component({
    selector: "app-app-mobile-menu",
    templateUrl: "./app-mobile-menu.component.html",
    styleUrls: ["./app-mobile-menu.component.css"],
})
export class AppMobileMenuComponent implements OnInit, OnDestroy {
    @Output() public menuAndCartChange = new EventEmitter();
    @Output() public sidenavToggle = new EventEmitter();
    sideMenuContext: boolean;
    shoppingCounter: number = 0;
    cartOrder: Array<ProductCartModel> = [];
    sideNavOpen = false;
    private destroy$: Subject<any> = new Subject();

    constructor(private cartService: CartService) {
        //Get the counter of product in order(Local Storage) through the CartService
        cartService.counterProductsInCart.subscribe((res: any) => {
            this.shoppingCounter = res;
        });
        //Get the order (Array of items in Local Storage) through the CartService
        cartService.ProductsLocalStorage.pipe(
            takeUntil(this.destroy$),
        ).subscribe((res: any) => {
            this.cartOrder = res;
        });
        // Get the status of sidenav
        cartService.sidenavOpen
            .pipe(takeUntil(this.destroy$))
            .subscribe((res: any) => {
                this.sideNavOpen = res;
            });
    }

    //On initialization(refresh) get the number of items from local storage
    ngOnInit(): void {
        let order = JSON.parse(localStorage.getItem("order"));
        if (order != null) {
            for (let index = 0; index < order.length; index++) {
                this.shoppingCounter =
                    this.shoppingCounter + order[index].quantity;
            }
        }
    }

    //Set the side navgation to the Menu
    public MenuSideNav() {
        this.sideMenuContext = true;
        this.menuAndCartChange.emit(this.sideMenuContext);
        this.onToggleSidenav();
    }

    public closeSideNav() {
        if (this.sideNavOpen === true) {
            this.CartSideNav();
        }
    }

    //Set the side navigation to the Cart and export the order from local storage to the cart component

    public CartSideNav() {
        this.sideMenuContext = false;
        this.menuAndCartChange.emit(this.sideMenuContext);
        this.getCurrentOrder();
        this.cartService.counterShoppingCart(this.shoppingCounter);
        this.onToggleSidenav();
        this.cartService.changeSidenavStatus(!this.sideNavOpen);
    }

    //Toggle Sidenav
    public onToggleSidenav = () => {
        this.sidenavToggle.emit();
        this.cartService.orderPlacedReset(false);
    };

    getCurrentOrder() {
        let order = JSON.parse(localStorage.getItem("order"));
        this.cartService.changeOrder(order);
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
