<header class="header-section">
    <div class="header-area">
        <div class="header-top">
            <div class="container">
                <div class="top">
                    <ul class="left">
                        <li>
                            <i class="far fa-clock"></i>
                            Sun-Fri: 9am-6pm Saterday Closed
                        </li>
                        <li>
                            <i class="fas fa-map-marker-alt"></i>
                            60/A Soft Road 24th Street, NY 1200
                        </li>
                        <li>
                            <i class="fas fa-headset"></i>
                            Support@foodbuzz.com
                        </li>
                    </ul>
                    <ul class="right">
                        <li>
                            <a href="#"><i class="fab fa-twitter"></i></a>
                        </li>
                        <li>
                            <a href="#"><i class="fab fa-vimeo-v"></i></a>
                        </li>
                        <li>
                            <a href="#"><i class="fas fa-futbol"></i></a>
                        </li>
                        <li>
                            <a href="#"><i class="fab fa-behance"></i></a>
                        </li>
                        <li>
                            <a href="#"><i class="fas fa-wifi"></i></a>
                        </li>
                        <li>
                            <a href="#"><i class="fab fa-instagram"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="header-bottom">
            <div class="container">
                <div class="primary-menu">
                    <div class="logo">
                        <a href="/"
                            ><img src="/assets/images/cacat" alt="logo"
                        /></a>
                    </div>
                    <div class="main-area">
                        <div class="main-menu">
                            <ul>
                                <li><a href="#">Acasa</a></li>
                                <li><a href="#">Rezervari</a></li>
                                <li><a href="#">Contact</a></li>
                            </ul>
                        </div>
                        <div class="cart-search">
                            <ul>
                                <li class="search">
                                    <i class="icofont-search-2"></i>
                                </li>
                                <li
                                    class="cart-area"
                                    [ngClass]="
                                        miniCartStatus ? 'open' : 'close'
                                    "
                                    (click)="toggleMiniCartStatus()"
                                >
                                    <div class="cart-icon">
                                        <i class="icofont-cart-alt"></i>
                                    </div>
                                    <div class="count-item">04</div>
                                    <div class="cart-content">
                                        <div class="cart-title">
                                            <div class="add-item">
                                                4 Items Added
                                            </div>
                                            <div class="list-close">
                                                <a href="#">Close</a>
                                            </div>
                                        </div>
                                        <div class="cart-scr scrollbar">
                                            <div class="cart-con-item">
                                                <div class="cart-item">
                                                    <div class="cart-inner">
                                                        <div class="cart-top">
                                                            <div class="thumb">
                                                                <a href="#"
                                                                    ><img
                                                                        src="/assets/images/product/01.jpg"
                                                                        alt=""
                                                                /></a>
                                                            </div>
                                                            <div
                                                                class="content"
                                                            >
                                                                <a href="#"
                                                                    >Split
                                                                    Remedy Split
                                                                    End
                                                                    Shampoo</a
                                                                >
                                                            </div>
                                                            <div
                                                                class="remove-btn"
                                                            >
                                                                <a href="#"
                                                                    ><i
                                                                        class="icofont-close"
                                                                    ></i
                                                                ></a>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="cart-bottom"
                                                        >
                                                            <div
                                                                class="sing-price"
                                                            >
                                                                Tk. 140
                                                            </div>
                                                            <div
                                                                class="cart-plus-minus"
                                                            >
                                                                <div
                                                                    class="dec qtybutton"
                                                                >
                                                                    -
                                                                </div>
                                                                <div
                                                                    class="dec qtybutton"
                                                                >
                                                                    -
                                                                </div>
                                                                <input
                                                                    class="cart-plus-minus-box"
                                                                    type="text"
                                                                    name="qtybutton"
                                                                    value="1"
                                                                />
                                                                <div
                                                                    class="inc qtybutton"
                                                                >
                                                                    +
                                                                </div>
                                                                <div
                                                                    class="inc qtybutton"
                                                                >
                                                                    +
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="total-price"
                                                            >
                                                                Tk. 280.00
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="cart-item">
                                                    <div class="cart-inner">
                                                        <div class="cart-top">
                                                            <div class="thumb">
                                                                <a href="#"
                                                                    ><img
                                                                        src="/assets/images/product/02.jpg"
                                                                        alt=""
                                                                /></a>
                                                            </div>
                                                            <div
                                                                class="content"
                                                            >
                                                                <a href="#"
                                                                    >Split
                                                                    Remedy Split
                                                                    End
                                                                    Shampoo</a
                                                                >
                                                            </div>
                                                            <div
                                                                class="remove-btn"
                                                            >
                                                                <a href="#"
                                                                    ><i
                                                                        class="icofont-close"
                                                                    ></i
                                                                ></a>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="cart-bottom"
                                                        >
                                                            <div
                                                                class="sing-price"
                                                            >
                                                                Tk. 140
                                                            </div>
                                                            <div
                                                                class="cart-plus-minus"
                                                            >
                                                                <div
                                                                    class="dec qtybutton"
                                                                >
                                                                    -
                                                                </div>
                                                                <div
                                                                    class="dec qtybutton"
                                                                >
                                                                    -
                                                                </div>
                                                                <input
                                                                    class="cart-plus-minus-box"
                                                                    type="text"
                                                                    name="qtybutton"
                                                                    value="1"
                                                                />
                                                                <div
                                                                    class="inc qtybutton"
                                                                >
                                                                    +
                                                                </div>
                                                                <div
                                                                    class="inc qtybutton"
                                                                >
                                                                    +
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="total-price"
                                                            >
                                                                Tk. 280.00
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="cart-item">
                                                    <div class="cart-inner">
                                                        <div class="cart-top">
                                                            <div class="thumb">
                                                                <a href="#"
                                                                    ><img
                                                                        src="/assets/images/product/03.jpg"
                                                                        alt=""
                                                                /></a>
                                                            </div>
                                                            <div
                                                                class="content"
                                                            >
                                                                <a href="#"
                                                                    >Split
                                                                    Remedy Split
                                                                    End
                                                                    Shampoo</a
                                                                >
                                                            </div>
                                                            <div
                                                                class="remove-btn"
                                                            >
                                                                <a href="#"
                                                                    ><i
                                                                        class="icofont-close"
                                                                    ></i
                                                                ></a>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="cart-bottom"
                                                        >
                                                            <div
                                                                class="sing-price"
                                                            >
                                                                Tk. 140
                                                            </div>
                                                            <div
                                                                class="cart-plus-minus"
                                                            >
                                                                <div
                                                                    class="dec qtybutton"
                                                                >
                                                                    -
                                                                </div>
                                                                <div
                                                                    class="dec qtybutton"
                                                                >
                                                                    -
                                                                </div>
                                                                <input
                                                                    class="cart-plus-minus-box"
                                                                    type="text"
                                                                    name="qtybutton"
                                                                    value="1"
                                                                />
                                                                <div
                                                                    class="inc qtybutton"
                                                                >
                                                                    +
                                                                </div>
                                                                <div
                                                                    class="inc qtybutton"
                                                                >
                                                                    +
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="total-price"
                                                            >
                                                                Tk. 280.00
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="cart-item">
                                                    <div class="cart-inner">
                                                        <div class="cart-top">
                                                            <div class="thumb">
                                                                <a href="#"
                                                                    ><img
                                                                        src="/assets/images/product/04.jpg"
                                                                        alt=""
                                                                /></a>
                                                            </div>
                                                            <div
                                                                class="content"
                                                            >
                                                                <a href="#"
                                                                    >Split
                                                                    Remedy Split
                                                                    End
                                                                    Shampoo</a
                                                                >
                                                            </div>
                                                            <div
                                                                class="remove-btn"
                                                            >
                                                                <a href="#"
                                                                    ><i
                                                                        class="icofont-close"
                                                                    ></i
                                                                ></a>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="cart-bottom"
                                                        >
                                                            <div
                                                                class="sing-price"
                                                            >
                                                                Tk. 140
                                                            </div>
                                                            <div
                                                                class="cart-plus-minus"
                                                            >
                                                                <div
                                                                    class="dec qtybutton"
                                                                >
                                                                    -
                                                                </div>
                                                                <div
                                                                    class="dec qtybutton"
                                                                >
                                                                    -
                                                                </div>
                                                                <input
                                                                    class="cart-plus-minus-box"
                                                                    type="text"
                                                                    name="qtybutton"
                                                                    value="1"
                                                                />
                                                                <div
                                                                    class="inc qtybutton"
                                                                >
                                                                    +
                                                                </div>
                                                                <div
                                                                    class="inc qtybutton"
                                                                >
                                                                    +
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="total-price"
                                                            >
                                                                Tk. 280.00
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="cart-scr-bottom">
                                            <ul>
                                                <li>
                                                    <div class="title">
                                                        Subtotal
                                                    </div>
                                                    <div class="price">
                                                        Tk. 1045.00
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="title">
                                                        Shipping
                                                    </div>
                                                    <div class="price">
                                                        Tk. 40.00
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="title">
                                                        Cart Total
                                                    </div>
                                                    <div class="price">
                                                        Tk. 1085.00
                                                    </div>
                                                </li>
                                            </ul>
                                            <a href="#" class="food-btn"
                                                ><span>Place Order</span></a
                                            >
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
