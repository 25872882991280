import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuardService } from "./guards/auth-guard.service";
import { AppLocationLandingPageComponent } from "./app-location-landing-page/app-location-landing-page.component";
import { AppProductDescriptionComponent } from "./app-products/app-product-description/app-product-description.component";
import { PagenotfoundComponent } from "./pagenotfound/pagenotfound.component";

const routes: Routes = [
    {
        path: "",
        component: AppLocationLandingPageComponent,
        canActivate: [AuthGuardService],
    },
    //{ path: '', redirectTo: '/menu/ba5a5f4f-8c94-4dcf-be5f-f07374580d7e',  pathMatch: 'full'},
    //{ path: 'menu/ba5a5f4f-8c94-4dcf-be5f-f07374580d7e', component : AppLocationLandingPageComponent ,canActivate: [AuthGuardService]},
    //{ path: 'product/:id', component: AppProductDescriptionComponent}
    { path: "404", component: PagenotfoundComponent },
    //{path: '**', redirectTo: '/404'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
